import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'

//Styles
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: '30px',
        },
    },
    textfield: {
        '&.MuiFormControl-root': {
            marginTop: '30px',
        },
    },
    buttonPrimary: {
        width: '60%',
        height: '4.5rem',
        borderRadius: '0.938rem',
        color: theme.palette.text.white,
        fontSize: '1.25rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        margin: '50px 0px',
        background: 'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
        '&:hover': {
            background:
                'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '60%',
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '2.5rem',
            fontSize: '0.875rem',
            lineHeight: '1.125rem',
            margin: '24px 0px',
        },
    },
}))

const ContactForm = (props) => {
    const [formState, setFormState] = useState({})
    const [open, setOpen] = useState(false)

    const classes = useStyles()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    const submitHandler = (e) => {
        e.preventDefault()

        const body = {
            name: formState['name'],
            email: formState['email'],
            text: formState['message'],
        }
        fetch('https://www.melioeducation.com/api/sendMail', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((r) => r.json())
            .catch((error) => {
                // Errors are reported there
                console.log(error)
            })

        setFormState('')
        setOpen(true)
    }

    //setting the state everytime new form values are inputted

    const changeHandler = (fieldName) => (e) => {
        // e.persist()
        if (e.target && e.target.value && e.target.value !== '') {
            setFormState((prev) => {
                return {
                    ...prev,
                    [fieldName]: e.target && e.target.value,
                }
            })
        }
        if (e.target.value === '') {
            setFormState((prev) => {
                return {
                    ...prev,
                    [fieldName]: '',
                }
            })
        }
    }

    return (
        <Box>
            <form className={classes.root} onSubmit={submitHandler}>
                <FormControl fullWidth>
                    <TextField
                        className={classes.textfield}
                        id="standard-basic"
                        label="Full name"
                        variant="outlined"
                        required
                        value={formState['name'] || ''}
                        onChange={changeHandler('name')}
                        placeholder="Please enter your full name"
                    />
                    <TextField
                        className={classes.textfield}
                        label="E-mail"
                        required
                        variant="outlined"
                        onChange={changeHandler('email')}
                        placeholder="Please enter your e-mail address"
                        value={formState['email'] || ''}
                    />
                    <TextField
                        className={classes.textfield}
                        id="filled-multiline-static"
                        label="Message"
                        variant="outlined"
                        required
                        multiline
                        rows={4}
                        placeholder="Please type your message"
                        value={formState['message'] || ''}
                        onChange={changeHandler('message')}
                    />
                    <Button
                        className={classes.buttonPrimary}
                        variant="contained"
                        disableElevation
                        size="large"
                        color="primary"
                        type="submit"
                    >
                        Submit
                    </Button>
                </FormControl>
            </form>
            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Thank you. Your message has been sent succesfully!
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default ContactForm
