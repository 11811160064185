import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import TopBar from '../../components/GeneratedTopBarNavigation'
import Footer from '../../components/GeneratedFooter'
import ContactForm from '../../components/ContactForm'

const Page = (props) => {
    const { contentIds, ...context } = props.pageContext

    return (
        <>
            <TopBar />
            <Box pt={15}>
                <Container maxWidth="sm">
                    <h1>Contact us!</h1>
                    <ContactForm />
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default Page
